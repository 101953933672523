import {computed,inject,Injectable} from '@angular/core';
import {HttpClient,HttpErrorResponse} from '@angular/common/http';
import {LoginRequestDto} from './dto/login-request.dto';
import {catchError,Observable,of,tap,throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {CookieService} from 'ngx-cookie-service';
import {UserResponseDto} from './dto/user-response.dto';
import {DOCUMENT} from '@angular/common';
import {SignUpRequestDto} from './dto/sign-up-request.dto';
import {PasswordResetTokenDto} from './dto/password-reset-token.dto';
import {rxResource,toObservable} from '@angular/core/rxjs-interop';
import {ResetPasswordRequestDto} from './dto/reset-password-request.dto';
import {CheckNumberResponseDto} from '../common/dto/check-number-response.dto';
import {CheckStringResponseDto} from '../common/dto/check-string-response.dto';

@Injectable({
	providedIn:'root'
})
export class AuthenticationService{
	private readonly document=inject(DOCUMENT);
	private httpClient=inject(HttpClient);
	private cookieService=inject(CookieService);
	private userResource=rxResource<UserResponseDto|null|undefined,unknown>({
		loader:()=>this.getUser().pipe(
			catchError((error)=>{
				if(error.status===401){
					return of(null);
				}else{
					return throwError(()=>error);
				}
			})
		)
	});
	user=computed<UserResponseDto|null|undefined>(
		()=>this.userResource.value()
	);
	userObservable=toObservable(this.user);
	
	reloadUser():void{
		this.userResource.reload();
	}
	
	getUser():Observable<UserResponseDto>{
		return this.httpClient.get<UserResponseDto>(`${environment.apiServer.url}/api/authentication/user`,{withCredentials:true});
	}
	
	logout():void{
		this.httpClient.post(`${environment.apiServer.url}/api/authentication/logout`,{},{withCredentials:true})
		.pipe(
			tap(()=>{
				this.document.location.reload();
			}),
			catchError((error:HttpErrorResponse)=>{
				setTimeout(()=>this.document.location.reload(),200);
				return throwError(()=>error);
			})
		)
		.subscribe({next:console.log,error:console.error});
	}
	
	login(data:LoginRequestDto):Observable<UserResponseDto|null|undefined>{
		return this.httpClient.post<UserResponseDto>(`${environment.apiServer.url}/api/authentication/login`,data,{withCredentials:true});
	}
	
	signUp(data:SignUpRequestDto):Observable<UserResponseDto|null|undefined>{
		return this.httpClient.post<UserResponseDto>(`${environment.apiServer.url}/api/authentication/signup`,data,{withCredentials:true});
	}
	
	passwordResetToken(data:PasswordResetTokenDto):Observable<CheckStringResponseDto>{
		return this.httpClient.post<CheckStringResponseDto>(`${environment.apiServer.url}/api/authentication/password-reset-token`,data,{withCredentials:true});
	}
	
	resetPassword(data:ResetPasswordRequestDto):Observable<CheckNumberResponseDto>{
		return this.httpClient.post<CheckNumberResponseDto>(`${environment.apiServer.url}/api/authentication/reset-password`,data,{withCredentials:true});
	}
	
}
